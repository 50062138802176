import React, { useContext, useEffect, useState } from 'react';
import {
  Field,
  ImageField,
  SitecoreContext,
  withSitecoreContext,
} from '@sitecore-jss/sitecore-jss-react';

import './index.scss';
import { FaAngleDoubleDown, FaCheckCircle, FaHistory, FaShareAlt } from 'react-icons/fa';
import { IProductMetaData } from '../../model/ProductMetaData';
import PreviewStandardImage from '../PreviewStandardImage';
import 'react-loading-skeleton/dist/skeleton.css';

import CustomTooltip from '../CustomTooltip';
import LoadingSkeleton from '../LoadingSkeleton';
import { ProductDataContext, ProductContextType } from '../../context/productContext';
import { useHistory, useLocation } from 'react-router-dom';
import { addEllipsis } from '../../utils/helper';
import { ShareLinkDialog } from '../ShareLinkDialog';
import { isLoggedIn } from '../../utils/authHelper';

type ProductMetaDataProps = {
  fields: {
    Status: Field<string>;
    ReaffirmedTooltip: Field<string>;
    ThumbnailImage: ImageField;
    PreviewBaseUrl: Field<string>;
    ThumbnailBaseUrl: Field<string>;
    AmendmentBaseUrl: Field<string>;
    AddendumTooltip: Field<string>;
    PreviousEditionTooltip: Field<string>;
    RedlineTooltip: Field<string>;
    TextCopiedMessage: Field<string>;
    CopyButton: Field<string>;
    CopyLinkLable: Field<string>;
  };
  sitecoreContext: SitecoreContext & { pageEditing: boolean };
  Img: ImageField;
};

function IsProductMostRecent(isRecent: boolean) {
  if (isRecent === true) {
    return (
      <div className="mostrecentdiv">
        <span>
          <FaCheckCircle className="icon checkcircleicon" />
        </span>
        <span className="mostrecenttext">
          <span>MOST RECENT</span>
        </span>
      </div>
    );
  } else {
    return (
      <div className="mostrecentdiv">
        <span>
          <FaHistory className="icon previousicon" />
        </span>
        <span className="previoustext">
          <span>PREVIOUS EDITION</span>
        </span>
      </div>
    );
  }
}

const ProductMetaData = ({ fields, sitecoreContext }: ProductMetaDataProps): JSX.Element => {
  const history = useHistory();
  const location = useLocation();
  const [productMetaDataDetails, setProductMetaDataDetails] = useState<IProductMetaData>();
  const [isLoading, setIsLoading] = useState(true);
  const { productDetail } = useContext(ProductDataContext) as ProductContextType;
  const [openPopup, setOpenPopup] = useState(false);

  useEffect(() => {
    if (productDetail?.productMetaData) {
      setProductMetaDataDetails(productDetail.productMetaData);
      setIsLoading(false);
    }
  }, [productDetail?.productMetaData]);

  function renderWithLoading(value: any, theme?: any, loaderWidth?: any, loaderHeight?: any) {
    return isLoading ? (
      <LoadingSkeleton theme={theme} loaderWidth={loaderWidth} loaderHeight={loaderHeight} />
    ) : (
      value
    );
  }

  const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  function niceBytes(x: any) {
    let l = 0,
      n = parseInt(x, 10) || 0;
    while (n >= 1024 && ++l) {
      n = n / 1024;
    }
    return n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l];
  }

  function scrollToSection(sectionName: string) {
    const divElement = document.getElementById(sectionName);
    divElement?.scrollIntoView({ behavior: 'smooth' });
  }

  function gotoRecentEdition() {
    if (location.pathname.includes('/standards/')) {
      history.push(location.pathname);
    }
  }

  const handlePopupOpen = () => {
    setOpenPopup(true);
  };

  const handlePopupClose = () => {
    setOpenPopup(false);
  };

  return (
    <div className="productmetadata">
      {sitecoreContext.pageEditing && (
        <>
          <p>ProductMetaData Component Edit Mode</p>
        </>
      )}
      <div className="mainProductMetaDiv">
        {openPopup && (
          <div>
            <ShareLinkDialog
              fields={{
                TextCopiedMessage: fields.TextCopiedMessage,
                CopyButton: fields.CopyButton,
                CopyLinkLable: fields.CopyLinkLable,
              }}
              props={{
                openPopup: openPopup,
                closePopup: handlePopupClose,
                shareLink: window.location.href,
              }}
            ></ShareLinkDialog>
          </div>
        )}

        <div className="productMetaDataLeft">
          {renderWithLoading(
            productMetaDataDetails && (
              <PreviewStandardImage
                previewStdFields={{
                  previewUrl: productMetaDataDetails.previewUrl,
                  thumbnailUrl: productMetaDataDetails.thumbnailUrl,
                  isDatasheet: productMetaDataDetails.isDatasheet,
                }}
                fields={{
                  ThumbnailImage: fields.ThumbnailImage,
                }}
                PreviewBaseUrl={fields?.PreviewBaseUrl}
                ThumbnailBaseUrl={fields?.ThumbnailBaseUrl}
              ></PreviewStandardImage>
            ),
            'dark',
            '120px'
          )}
        </div>
        <div className="ProductMetaDataRight">
          <div>
            {renderWithLoading(
              productMetaDataDetails ? IsProductMostRecent(productMetaDataDetails.isRecent) : <></>
            )}
          </div>
          <div className="product-meta-data-details">
            <div
              className="producttitle"
              title={
                productMetaDataDetails && productMetaDataDetails.title.length >= 200
                  ? productMetaDataDetails?.title + '...'
                  : productMetaDataDetails?.title
              }
            >
              <span>
                {renderWithLoading(productMetaDataDetails?.title)}
                {addEllipsis(productMetaDataDetails?.title)}
              </span>
            </div>
            {isLoggedIn() && (
              <div className="share-icon-div">
                <FaShareAlt className="shareicon" onClick={handlePopupOpen}></FaShareAlt>
              </div>
            )}
          </div>
          <br></br>
          <div className="productfields">
            <div className="productmetadatafields">
              <span className="productmetadatafieldtitle">Edition:</span>
              <br></br>
              <div className="productmetadatafieldvalue">
                {renderWithLoading(productMetaDataDetails?.edition)}
              </div>
            </div>
            <div className="right-divider" />
            <div className="productmetadatafields">
              <span className="productmetadatafieldtitle">Published:</span>
              <br></br>
              <div className="productmetadatafieldvalue">
                {renderWithLoading(productMetaDataDetails?.publishedDate)}
              </div>
            </div>
            <div className="right-divider" />
            {productMetaDataDetails &&
              !productMetaDataDetails?.isDatasheet &&
              productMetaDataDetails?.reaffirmedDate !== '01/01/0001' &&
              productMetaDataDetails.reaffirmedDate !== undefined &&
              productMetaDataDetails.reaffirmedDate !== '' && (
                <>
                  <div className="productmetadatafields">
                    <div className="reaffirmedTitleSection">
                      <span className="productmetadatafieldtitle">Reaffirmed:</span>
                      <CustomTooltip
                        fields={{
                          'Tooltip Text': fields.ReaffirmedTooltip,
                        }}
                      />
                    </div>
                    <div className="reaffirmedfieldvalue">
                      {renderWithLoading(
                        productMetaDataDetails?.reaffirmedDate
                          ? productMetaDataDetails?.reaffirmedDate
                          : '-'
                      )}
                    </div>
                  </div>
                  <div className="right-divider" />
                </>
              )}
            <div className="productmetadatafields">
              <span className="productmetadatafieldtitle">Status:</span>
              <br></br>
              <span className="productmetadatafieldvalue">
                {renderWithLoading(productMetaDataDetails?.status)}
              </span>
            </div>
            <div className="right-divider" />
            <div className="productmetadatafields">
              <span className="productmetadatafieldtitle">Pages:</span>
              <br></br>
              <span className="productmetadatafieldvalue">
                {renderWithLoading(productMetaDataDetails?.pages)}
              </span>
            </div>
            <div className="right-divider" />
            <div className="productmetadatafields">
              <span className="productmetadatafieldtitle">File Size:</span>
              <br></br>
              <span className="productmetadatafieldvalue">
                {renderWithLoading(niceBytes(productMetaDataDetails?.fileSize))}
              </span>
            </div>
            <div className="right-divider" />
            <div className="productmetadatafields">
              <span className="productmetadatafieldtitle">Language:</span>
              <br></br>
              <span className="productmetadatafieldvalue">
                {renderWithLoading(productMetaDataDetails?.language)}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="product-align">
        <div className="scrollsection">
          <div className="scrollSectionLeftMargin"></div>
          <span className="scrolllinksection">
            <a className="scrollinktext" onClick={() => scrollToSection('descriptionDiv')}>
              Description
            </a>
            <FaAngleDoubleDown
              className="scrolldownicon"
              onClick={() => scrollToSection('descriptionDiv')}
            ></FaAngleDoubleDown>
          </span>
          {productMetaDataDetails?.isAmendmentExist && (
            <span className="scrolllinksection">
              <div className="AddendaSection">
                <a className="scrollinktext" onClick={() => scrollToSection('addendaErrataDiv')}>
                  Addenda/Errata Available
                </a>
                <CustomTooltip
                  fields={{
                    'Tooltip Text': fields.AddendumTooltip,
                  }}
                />
              </div>
              <FaAngleDoubleDown
                className="scrolldownicon"
                onClick={() => scrollToSection('addendaErrataDiv')}
              ></FaAngleDoubleDown>
            </span>
          )}
          {productMetaDataDetails?.isPreviousEditionsExist && (
            <span className="scrolllinksection">
              <div className="PreviousEditionSection">
                <a className="scrollinktext" onClick={() => scrollToSection('previousEditionsDiv')}>
                  Previous Editions
                </a>
                <CustomTooltip
                  fields={{
                    'Tooltip Text': fields.PreviousEditionTooltip,
                  }}
                />
              </div>
              <FaAngleDoubleDown
                className="scrolldownicon"
                onClick={() => scrollToSection('previousEditionsDiv')}
              ></FaAngleDoubleDown>
            </span>
          )}
          {location.pathname.includes('/standards/') && !productMetaDataDetails?.isRecent && (
            <span className="scrolllinksection">
              <div className="PreviousEditionSection">
                {renderWithLoading(
                  <a className="scrollinktext" onClick={() => gotoRecentEdition()}>
                    View Recent Edition
                  </a>
                )}
              </div>
            </span>
          )}
          {productMetaDataDetails?.isRedlineExist && (
            <span className="scrolllinksection">
              <div className="RedlineAvailableSection">
                <a className="scrollinktext" style={{ cursor: 'default' }}>
                  Redline Available
                </a>
                <CustomTooltip
                  fields={{
                    'Tooltip Text': fields.RedlineTooltip,
                  }}
                />
              </div>
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default withSitecoreContext()(ProductMetaData);
