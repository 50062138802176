import React, { useContext, useEffect, useState } from 'react';
import { Field, RichText, SitecoreContext } from '@sitecore-jss/sitecore-jss-react';

import './index.scss';
import '../../assets/styles/_buttons.scss';

import { Button } from '@material-ui/core';

import { Link } from 'react-router-dom';
import CustomTooltip from '../CustomTooltip';
import { getAPICall } from '../../dataFetcher/axiosMethodCalls';
import { getCurrencyInUSD } from '../../utils/helper';
import { APIWebstoreContext } from '../../context/globalContext';
import LoadingSkeleton from '../LoadingSkeleton';
import { CartItemsDetails } from '../ProductCartDetails';
import { CartAnalyticsInfo, checkoutEvent } from '../../utils/analyticsHelper';
import { WebstoreDataService } from '../../utils/WebstoreDataService';

export type OrderSummaryProps = {
  fields: {
    MemberDiscountTooltipText: Field<string>;
    SubscriptionText: Field<string>;
    SingleStandards: Field<number>;
    MultipleStandards: Field<number>;
    NavigateTo: Field<string>;
  };
  sitecoreContext: SitecoreContext & { pageEditing: boolean };
};

export interface OrderSummaryDetails {
  orderId: string;
  subTotal: number;
  memberDiscount: number;
  total: number;
  taxTotal: number;
  shippingTotal: number;
}

export const OrderSummary = ({ fields }: OrderSummaryProps): JSX.Element => {
  const { state } = useContext(APIWebstoreContext);
  const [orderSummaryDetails, setOrderSummaryDetails] = useState<OrderSummaryDetails>();
  const [isLoading, setIsLoading] = useState(true);

  const fetchData = () => {
    getAPICall(`${process.env.REACT_APP_CART_URL}/cartOrder/ordersummary`).then(function (
      response: any
    ) {
      if (response && response.data) {
        setOrderSummaryDetails(response.data);
        setIsLoading(false);
      }
    });
  };

  useEffect(() => {
    fetchData();
  }, [state?.toggleOrderSummaryLoad]);

  function formJSONAndPushToGTM() {
    const cartInfo: Array<CartItemsDetails> = WebstoreDataService.getCartInfo().cartInfo;
    if (cartInfo) {
      let value: any;
      value = orderSummaryDetails?.total.toFixed(2);

      const itemAnalyticsInfo: CartAnalyticsInfo = {
        currency: 'USD',
        value: value,
        items:
          cartInfo &&
          cartInfo.map((item: CartItemsDetails) => {
            let price: any = item?.priceOption?.unitPrice;
            price = price.toFixed(2);
            return {
              item_id: `${item?.product?.productId}`,
              item_name: `${item?.product?.title}`,
              item_brand: `${item?.product?.productType}`,
              item_variant: item?.priceOption.variantId,
              price: price,
              quantity: item?.priceOption?.quantity,
            };
          }),
      };
      checkoutEvent(itemAnalyticsInfo);
    }
  }

  function renderWithLoading(value: any, theme?: any) {
    return isLoading ? <LoadingSkeleton theme={theme} /> : value;
  }

  function getSubscriptionBox() {
    const singleStandardsCheck =
      Number(state?.maxCartItemQuantity) >= fields?.SingleStandards?.value;
    const differentStandardsCheck = Number(state?.cartCount) >= fields?.MultipleStandards?.value;
    if (singleStandardsCheck || differentStandardsCheck) {
      return (
        <div className="optInDiv">
          <div className="subscriptionNotifications">
            <label className="subscriptionNotificationText">
              <p className="subscriptionHeading">Subscriptions</p>
              <RichText className="description" field={fields?.SubscriptionText} />
            </label>
            <br></br>
          </div>
        </div>
      );
    } else {
      return <></>;
    }
  }
  function getOrderSummary() {
    if (Number(state?.cartCount) > 0) {
      return (
        <div className="orderSummary">
          <h1>Order Summary</h1>

          <div className="orderPriceDetails">
            <div className="orderSubTotal justify-space-between">
              <p className="orderSubTotalDetail">Product Subtotal:</p>
              <p className="orderSubTotalDetail">
                {renderWithLoading(
                  orderSummaryDetails && getCurrencyInUSD(orderSummaryDetails?.subTotal)
                )}
              </p>
            </div>
            <div className="orderMemberDiscount justify-space-between">
              <div className="orderMemberDiscountTitleSection">
                <p>Member Discount (30%):</p>
                <CustomTooltip
                  fields={{
                    'Tooltip Text': fields?.MemberDiscountTooltipText,
                  }}
                />
              </div>
              <p>
                {renderWithLoading(
                  orderSummaryDetails && getCurrencyInUSD(orderSummaryDetails?.memberDiscount),
                  'light'
                )}
              </p>
            </div>
            <div className="orderShippingAndHandling justify-space-between">
              <p className="orderShippingDetail">Shipping and Handling:</p>
              <p className="orderShippingDetail shippingDetailNoWrap">Calculated at checkout</p>
            </div>
            <div className="orderSalesTax justify-space-between">
              <p className="orderSalesTaxDetail">Sales Tax:</p>
              <p className="orderSalesTaxDetail" style={{ textAlign: 'right' }}>
                Calculated at checkout
              </p>
            </div>
            <div className="orderTotal justify-space-between">
              <div className="orderTotalSection  justify-space-between">
                <p className="orderTotalSection">Total:</p>
                <p>
                  {renderWithLoading(
                    orderSummaryDetails && getCurrencyInUSD(orderSummaryDetails?.total)
                  )}
                </p>
              </div>
              <div className="orderButtonSection">
                {renderWithLoading(
                  <Link to="/order/checkout" id="Checkout">
                    <Button
                      className="ws-white-button"
                      variant="contained"
                      onClick={formJSONAndPushToGTM}
                    >
                      CHECKOUT
                    </Button>
                  </Link>
                )}
                {renderWithLoading(
                  <Link to={fields.NavigateTo?.value} id="ContinueShopping">
                    <Button className="ws-primary-button" variant="contained">
                      CONTINUE SHOPPING
                    </Button>
                  </Link>
                )}
              </div>
            </div>
          </div>
          {getSubscriptionBox()}
          <br />
        </div>
      );
    } else {
      return <div className="orderSummary"></div>;
    }
  }
  return <div className="orderSummarySticky">{getOrderSummary()}</div>;
};

export default OrderSummary;
