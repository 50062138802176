import {
  buildSearchBox,
  buildResultList,
  buildFacet,
  buildPager,
  buildSort,
  buildResultsPerPage,
  buildQuerySummary,
  buildBreadcrumbManager,
  buildDidYouMean,
  buildSearchStatus,
  buildHistoryManager,
  buildFacetManager,
} from '@coveo/headless';
import { headlessEngine } from './engine';
import { criteria } from './sort-criteria';

export const searchBox = buildSearchBox(headlessEngine, {
  options: {
    highlightOptions: {
      notMatchDelimiters: {
        open: '<strong>',
        close: '</strong>',
      },
      correctionDelimiters: {
        open: '<i>',
        close: '</i>',
      },
    },
  },
});

export const didYouMean = buildDidYouMean(headlessEngine);

export const facetBreadcrumbs = buildBreadcrumbManager(headlessEngine);

export const resultList = buildResultList(headlessEngine, {
  options: {
    fieldsToInclude: [
      'description',
      'mostrecenttext',
      'price',
      'edition',
      'productcode',
      'category',
      'filesize',
      'publisheddate',
      'type',
      'isaddendaerrataavailable',
      'otherlanguagesavailable',
      'status',
      'ismostrecent',
      'addendaerratatext',
      'historicaleditionstext',
      'otherlanguagestext',
      'ispreviousedition',
      'segment',
      'committee',
      'subcommittee',
      'industrysegment',
      'industrysubjectmatter',
      'isdatasheet',
      'historicaltext',
      'producttype',
      'programs',
    ],
  },
});

export const facetManager = buildFacetManager(headlessEngine);

export const segmentFacet = buildFacet(headlessEngine, {
  options: { numberOfValues: 5, field: 'segment' },
});

export const yearFacet = buildFacet(headlessEngine, {
  options: { numberOfValues: 5, field: 'year', sortCriteria: 'alphanumericDescending' },
});

export const industrySegmentFacet = buildFacet(headlessEngine, {
  options: { numberOfValues: 5, field: 'industrysegment' },
});

export const industrySubjectMatterFacet = buildFacet(headlessEngine, {
  options: { numberOfValues: 5, field: 'industrysubjectmatter' },
});

export const relatedProgramsFacet = buildFacet(headlessEngine, {
  options: { numberOfValues: 5, field: 'programs' },
});

export const languagesFacet = buildFacet(headlessEngine, {
  options: { numberOfValues: 5, field: 'supportedlanguages' },
});

export const pager = buildPager(headlessEngine);

export const resultsperpage = buildResultsPerPage(headlessEngine);
export const querySummary = buildQuerySummary(headlessEngine);
export const resultSummary = buildQuerySummary(headlessEngine);

const initialCriterion = criteria[0][1];
export const sort = buildSort(headlessEngine, {
  initialState: { criterion: initialCriterion },
});

export const searchStatus = buildSearchStatus(headlessEngine);

export const historyManager = buildHistoryManager(headlessEngine);
