import React, { useContext, useEffect, useState } from 'react';
import { SitecoreContext, withSitecoreContext, Field } from '@sitecore-jss/sitecore-jss-react';
import ToastNotification from '../ToastNotification';
import './index.scss';
import { Button } from '@material-ui/core';
import { Redirect } from 'react-router-dom';
import { postAPIAsyncCall, postAPICall } from '../../dataFetcher/axiosMethodCalls';
import { APIWebstoreContext } from '../../context/globalContext';
import LoadingSection from '../LoadingSection';
import { ProcessOrderValidationService } from '../CheckoutPage/ProcessOrderValidationService';
import LoadingIcon from '../LoadingIcon';
import PaymentErrors from './PaymentErrors';
import { Modal } from '@material-ui/core';
import { DialogActions, DialogContent, DialogTitle } from '../DialogComponent';
import { CartItemsDetails } from '../ProductCartDetails';
import { OrderAnalyticsInfo, purchaseEvent } from '../../utils/analyticsHelper';
import { WebstoreDataService } from '../../utils/WebstoreDataService';
import { STATUS_OK, STATUS_REDIRECT } from '../../utils/constants';
import { CountryFieldProps } from '../OrderSummaryCheckout';

export interface ErrorResponse {
  errorCode: string;
  errorMessage: string;
}

export interface IPayment {
  AccountNumber: string;
  CardType: string;
  ExpirationDate: string;
  CVV: string;
  CardholderName: string;
}

export interface OrderResponse {
  error: ErrorResponse;
  orderId: string;
  responseStatus: string;
}

export type PlaceOrderProcessProps = {
  fields: {
    ToastMessage: Field<string>;
    ToastMessagePayFabric: Field<string>;
  };
  orderId: string;
  isEULA: boolean;
  isOrderLoading: boolean;
  sitecoreContext: SitecoreContext & { pageEditing: boolean };
  taxExemptPending: boolean;
  orderTotal: number;
  taxTotal: number;
  shippingTotal: number;
  orderSummaryBtnText?: string;
  language?: string;
  restrictedCountries?: Array<CountryFieldProps>;
};

const PlaceOrderProcess = (props: PlaceOrderProcessProps): JSX.Element => {
  const [isLoading, setIsLoading] = useState(false);
  const { state, dispatch } = useContext(APIWebstoreContext);
  const [navigateOnOrderSubmit, setNavigateOnOrderSubmit] = useState('');
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState<Field<string>>();
  const [customToastMessage] = useState(props.fields.ToastMessage);
  const [confirmPopup, setConfirmPopup] = useState(false);

  function formJSONAndPushToGTM(orderId: string) {
    const cartInfo: Array<CartItemsDetails> = WebstoreDataService.getCartInfo().cartInfo;
    let value: any = props?.orderTotal;
    value = value.toFixed(2);
    if (cartInfo) {
      const orderAnalyticsInfo: OrderAnalyticsInfo = {
        transaction_id: orderId !== undefined ? orderId : '',
        tax: props?.taxTotal.toFixed(2),
        shipping: props?.shippingTotal.toFixed(2),
        value: value,
        currency: 'USD',
        payment_type: state?.paymentType,
        items:
          cartInfo &&
          cartInfo.map((item: CartItemsDetails) => {
            let price: any = item?.priceOption?.unitPrice;
            price = price.toFixed(2);
            return {
              item_id: `${item?.product?.productId}`,
              item_name: `${item?.product?.title}`,
              item_brand: `${item?.product?.productType}`,
              item_variant: item?.priceOption.variantId,
              price: price,
              quantity: item?.priceOption?.quantity,
            };
          }),
      };
      purchaseEvent(orderAnalyticsInfo);
    }
  }
  const toastRemove = () => {
    setShowToast(false);
  };

  useEffect(() => {
    if (props?.taxExemptPending) {
      showCustomToast(
        state?.sitecoreDictionaryData
          ? state?.sitecoreDictionaryData.TaxExemptPendingMessage
          : 'Your checkout is disabled until tax exempt verification is completed. It takes us about 2-3 days to get the verification completed'
      );
    }
  }, []);

  function placeOrderProcessStatus(isLoading: boolean) {
    if (isLoading) {
      return (
        <LoadingSection
          open={isLoading}
          message="Please do not go back or close the window or hit the button again"
        />
      );
    } else {
      return <div></div>;
    }
  }

  const showCustomToast = (message: string) => {
    customToastMessage.value = message;
    setToastMessage(customToastMessage);
    setShowToast(true);
  };

  const validateShippingAddress = async (data: any) => {
    let APIResponse: any = {};
    let statusResponse: any;
    let addressValidationStatusCode: any;

    const validateShippingAddressRequest = {
      city: data.city,
      country: data.country,
      line1: data.street1,
      line2: data.street2,
      postalCode: data.zip,
      region: data.state,
    };

    if (data.country === 'US' || data.country === 'CA') {
      APIResponse = await postAPICall(
        `${process.env.REACT_APP_PAYMENT_URL}/address/IsNAAddressValid`,
        validateShippingAddressRequest
      );
      statusResponse = APIResponse.data;
      addressValidationStatusCode = undefined;
    } else {
      APIResponse = await postAPICall(
        `${process.env.REACT_APP_PAYMENT_URL}/address/IsGlobalAddressValid`,
        validateShippingAddressRequest
      );
      statusResponse = APIResponse.data.status;
      addressValidationStatusCode = APIResponse.data.statusCode;
    }

    return { statusResponse, addressValidationStatusCode };
  };

  function hasBillingAddressErrors(billAddress: any) {
    if (
      billAddress.country.length < 2 ||
      billAddress.city === '' ||
      billAddress.firstName === '' ||
      billAddress.lastName === '' ||
      billAddress.postal === '' ||
      billAddress.state === '' ||
      billAddress.street === ''
    ) {
      return state?.sitecoreDictionaryData
        ? state?.sitecoreDictionaryData.BillingAddressErrorMessage
        : 'Billing Address Error';
    } else {
      return '';
    }
  }

  const handlePopupYes = () => {
    setConfirmPopup(false);
    submitOrderProcess(props.orderId, false);
  };

  const handleConfirmPopupClose = () => {
    setConfirmPopup(false);
  };

  function hasPOBoxAddress(shippingInfo: any) {
    const poBoxRegex =
      /\b(P\.O\.|PO|P O|P\.O|P O\.|PO\.)\s*(BOX|BOX\.)?\s*(\:|\-)?\s*(No|No\.|\#)?\s*\d+/i;
    return shippingInfo.street1.length > 0 && shippingInfo.street1.match(poBoxRegex) ? true : false;
  }

  function openAddressValidationPopup(openDialog: boolean) {
    return (
      <Modal
        open={openDialog}
        onClose={(_, reason) => {
          if (reason !== 'backdropClick') {
            handleConfirmPopupClose();
          }
        }}
        className="ws-modal-dialog"
      >
        <div className="addressValidationDialog addressValidationDialogPaper">
          <DialogTitle id="customized-dialog-title" onClose={handleConfirmPopupClose}>
            Address Validation
          </DialogTitle>
          <DialogContent dividers>
            {state?.sitecoreDictionaryData &&
            state?.sitecoreDictionaryData?.InvalidAddressConfirmation
              ? state?.sitecoreDictionaryData.InvalidAddressConfirmation
              : 'Unable to validate your address. Would you like to place the order without validating the address?'}
          </DialogContent>
          <DialogActions>
            <Button onClick={handlePopupYes} className="ws-primary-button-no-border">
              YES
            </Button>
            <Button onClick={handleConfirmPopupClose} className="ws-white-button">
              NO
            </Button>
          </DialogActions>
        </div>
      </Modal>
    );
  }

  async function processOrder(orderId: string) {
    let addressNotValidated = false;
    let addressValidated = false;

    if (state?.paymentType === 'CreditCard') {
      if (dispatch) {
        dispatch({
          type: 'CHECKOUT_UPDATE',
          payload: {
            toggleProcessOrderValidation: !state?.toggleProcessOrderValidation,
          },
        });
      }
    }

    let shippingInfo = ProcessOrderValidationService.getShippingInfo().shippingInfo;

    if (
      ProcessOrderValidationService.getShippingInfo().shippingInfo &&
      ProcessOrderValidationService.getShippingInfo().shippingInfo.toString() === '[object Object]'
    ) {
      try {
        if (
          shippingInfo &&
          shippingInfo.country === 'CN' &&
          state?.deliveryStatus?.includes('Print')
        ) {
          showCustomToast(
            state?.sitecoreDictionaryData &&
              state?.sitecoreDictionaryData.ChinaUserPrintProductNotification
              ? state?.sitecoreDictionaryData.ChinaUserPrintProductNotification
              : 'Shipping is unavailable in your region.  Please remove any print products and replace with PDF products in your cart to continue with your purchase.'
          );
          return;
        }

        if (
          shippingInfo &&
          ('' + shippingInfo.phone).replace('undefined', '').replace('null', '').trim() === '' &&
          state?.deliveryStatus?.includes('Print')
        ) {
          showCustomToast(
            state?.sitecoreDictionaryData &&
              state?.sitecoreDictionaryData?.PhoneNumberValidationError
              ? state?.sitecoreDictionaryData.PhoneNumberValidationError
              : 'Phone Number is required for all print orders. Please update the address with phone number.'
          );
          return;
        }

        if (shippingInfo) {
          const { statusResponse, addressValidationStatusCode } = await validateShippingAddress(
            shippingInfo
          );

          if (!statusResponse) {
            addressValidated = false;
            // Please follow the link for Strikeiron Status Codes: https://dev.azure.com/energyapi/eCommerce/_wiki/wikis/eCommerce.wiki/434/StrikeIron-Address-Validation
            if (addressValidationStatusCode && Number(addressValidationStatusCode) <= 325) {
              addressNotValidated = true;
            } else {
              showCustomToast(
                state?.sitecoreDictionaryData
                  ? state?.sitecoreDictionaryData.AddressValidationError
                  : 'Address Validation Error'
              );
              return;
            }
          } else {
            addressValidated = true;
          }
        } else {
          addressValidated = false;
          showCustomToast(
            state?.sitecoreDictionaryData
              ? state?.sitecoreDictionaryData.AddressValidationError
              : 'Address Validation Error'
          );
          return;
        }
      } catch (error: any) {
        addressValidated = false;
        showCustomToast(
          state?.sitecoreDictionaryData
            ? `${state?.sitecoreDictionaryData.AddressValidationFailureResponseMessage} - ${error?.response?.status} - ${error?.response?.statusText}`
            : 'Address Validation Response Failure Message'
        );
        return;
      }
    } else {
      addressValidated = false;
      showCustomToast(
        state?.sitecoreDictionaryData
          ? state?.sitecoreDictionaryData.MissingShippingInfoErrorMessage
          : 'Missing Shipping Information. Plese verify the details!'
      );
      return;
    }

    if (state?.deliveryStatus?.includes('Print') && hasPOBoxAddress(shippingInfo) === true) {
      showCustomToast(
        state?.sitecoreDictionaryData
          ? state?.sitecoreDictionaryData.AddressValidationPrintOrders
          : 'P.O. Box Address is not accepted for print orders. Please remove P.O. Box address from shipping address and re-submit the order.'
      );
      return;
    }

    if (!props.isEULA) {
      showCustomToast(
        state?.sitecoreDictionaryData
          ? state?.sitecoreDictionaryData.EULAErrorMessage
          : 'Please read and accept the EULA to proceed with checkout.'
      );
      return;
    }

    try {
      if (
        props.orderTotal > 0 &&
        shippingInfo &&
        props?.restrictedCountries?.some((x) => x.fields.CountryCode.value === shippingInfo.country)
      ) {
        setIsLoading(true);
        await redirectForExternalPaymentProcessing();
        return;
      }
    } catch (error: any) {
      showCustomToast(
        state?.sitecoreDictionaryData
          ? state?.sitecoreDictionaryData?.CheckoutExternalPaymentRedirectCatchError
          : 'Order Processing Failed. Please retry.'
      );
      setIsLoading(false);
      return;
    }

    if (state?.paymentType === undefined || state?.paymentType?.length === 0) {
      showCustomToast(
        state?.sitecoreDictionaryData
          ? state?.sitecoreDictionaryData.PayFabricErrorMessage
          : 'Payment failure. Please verify the details.'
      );
      return;
    }
    if (state?.paymentType === 'CreditCard') {
      if (
        ProcessOrderValidationService.getErrorMessage().text &&
        ProcessOrderValidationService.getErrorMessage().text !== ''
      ) {
        showCustomToast('' + ProcessOrderValidationService.getErrorMessage().text);
        return;
      }

      if (
        ProcessOrderValidationService.getBillingInfo().billingInfo.toString() !== '[object Object]'
      ) {
        let billingInfo = ProcessOrderValidationService.getBillingInfo().billingInfo();

        let errorMessage = hasBillingAddressErrors(billingInfo);

        if (errorMessage.length > 0) {
          showCustomToast(errorMessage);
          return;
        }
      }
    }

    if (addressNotValidated) {
      setConfirmPopup(true);
      return;
    }

    submitOrderProcess(orderId, addressValidated);
  }

  async function submitOrderProcess(orderId: any, addressValidated: boolean) {
    setIsLoading(true);

    let response = undefined;
    try {
      response = await calculateOrder(orderId);
      if (response && response.data && response.data.errors && response.data.errors?.length > 0) {
        showCustomToast(
          state?.sitecoreDictionaryData
            ? state?.sitecoreDictionaryData.OrderCalculationErrorMessage
            : 'Order Calculation Failure. Please verify the details and try again!'
        );
        setIsLoading(false);
        return;
      }
      if (response && response.data) {
        const orderTotal = response.data.total || 0;
        if (orderTotal !== props.orderTotal) {
          showCustomToast(
            state?.sitecoreDictionaryData
              ? state?.sitecoreDictionaryData.OrderCalculationErrorMessage
              : 'Order Calculation Failure. Please verify the details and try again!'
          );
          setIsLoading(false);
          return;
        }
      }
    } catch (error: any) {
      showCustomToast(
        state?.sitecoreDictionaryData
          ? state?.sitecoreDictionaryData.OrderCalculationErrorMessage
          : 'Order Calculation Failure. Please verify the details and try again!'
      );
      setIsLoading(false);
      return;
    }

    submitOrder(orderId, addressValidated);
  }

  const calculateOrder = async (orderid: string) => {
    const response = await postAPIAsyncCall(
      `${process.env.REACT_APP_CART_URL}/checkout/${orderid}/calculate`,
      {}
    );

    return response;
  };

  function findDebitCardType(ccNumber: string) {
    const regexPattern: { [key: string]: RegExp } = {
      MASTER_CARD: /^5[1-5][0-9]{1,}|^2[2-7][0-9]{1,}$/,
      VISA: /^4[0-9]{2,}$/,
      AMERICAN_EXPRESS: /^3[47][0-9]{5,}$/,
      UNION_PAY: /^(62[0-9]{14,17})$/,
    };

    for (const item in regexPattern) {
      if (ccNumber.replace(/[^\d]/g, '').match(regexPattern[item])) {
        return item;
      }
    }
    return '';
  }

  function submitOrder(orderid: string, addressValidated: boolean) {
    let billingInfo = null;
    let isSameAsShipping = true;

    let payment: IPayment | null = null;

    if (state?.paymentType === 'CreditCard') {
      isSameAsShipping = ProcessOrderValidationService.getBillingSameAsShipping().sameAsShipping;
      if (!isSameAsShipping) {
        let billData = ProcessOrderValidationService.getBillingInfo().billingInfo();
        billingInfo = {
          FirstName: billData.firstName,
          LastName: billData.lastName,
          Street1: billData.street,
          Street2: billData.unit,
          City: billData.city,
          State: billData.state,
          Zip: billData.postal,
          Country: billData.country,
          Phone: billData.phone,
        };
      }

      let ccInfoData = ProcessOrderValidationService.getCCInfo();
      let cardType = findDebitCardType(ccInfoData.ccinfo.card);
      payment = {
        AccountNumber: ccInfoData.ccinfo.card,
        CardType: cardType,
        ExpirationDate: ccInfoData.ccinfo.expiry,
        CVV: ccInfoData.ccinfo.securityCode,
        CardholderName: ccInfoData.ccinfo.cardHolder,
      };
    }

    let submitOrderRequest = {
      OrderId: orderid,
      PaymentMethod: state?.paymentType,
      Payment: payment,
      BillingIsSameAsShippingAddress: isSameAsShipping,
      BillingAddress: billingInfo,
      AddressValidated: addressValidated,
    };

    postAPICall(`${process.env.REACT_APP_CART_URL}/checkout/${orderid}/submit`, submitOrderRequest)
      .then(function (response: any) {
        if (response && response.status === 200) {
          processOrderResponse(response?.data);
        }
      })
      .catch(function (error) {
        // handle error
        if (error?.response?.data) {
          processOrderResponse(error?.response?.data);
        }
      });
    try {
      formJSONAndPushToGTM(orderid);
    } catch (error: any) {
      console.log(error);
    }
  }

  function processOrderResponse(orderResponse: OrderResponse) {
    if (orderResponse) {
      if (dispatch) {
        dispatch({
          type: 'CART_UPDATE',
          payload: {
            toggleCartQuantityLoad: !state?.toggleCartQuantityLoad,
            lastOrderId: orderResponse.orderId,
          },
        });
      }
      switch (orderResponse.responseStatus) {
        case 'Confirmed':
          setNavigateOnOrderSubmit('/order/confirmation');
          break;
        case 'Pending':
          setNavigateOnOrderSubmit('/order/pending');
          break;
        case 'Failed':
          if (orderResponse.error && orderResponse.error.errorCode === 'ServerError') {
            setNavigateOnOrderSubmit('/order/unsuccessful');
          } else if (
            orderResponse &&
            orderResponse.error &&
            orderResponse.error.errorCode === 'OrderSubmit.CCPaymentAlreadyPaid'
          ) {
            showCustomToast(
              state?.sitecoreDictionaryData
                ? state?.sitecoreDictionaryData.CCPaymentAlreadyPaid
                : 'Unsuccessful Order processing. Payment already submitted using Credit Card. Please choose the same payment method or contact support for further assistance.'
            );
          } else if (orderResponse && orderResponse.error && orderResponse.error.errorCode !== '') {
            let errorKey = Object.keys(PaymentErrors).findIndex(
              (key) => key === orderResponse.error.errorMessage
            );
            let errorMessage =
              errorKey >= 0
                ? Object.values(PaymentErrors).at(errorKey)
                : orderResponse.error.errorMessage;
            showCustomToast(
              errorMessage || 'Unsuccessful Order processing. Please verify the details!'
            );
          } else {
            showCustomToast(
              state?.sitecoreDictionaryData
                ? state?.sitecoreDictionaryData.OrderUnsuccessfulRetryMessage
                : 'Unsuccessful Order processing. Please verify the details!'
            );
          }
          setIsLoading(false);
          break;
        default:
          showCustomToast(
            state?.sitecoreDictionaryData
              ? state?.sitecoreDictionaryData.OrderUnsuccessfulRetryMessage
              : 'Unsuccessful Order processing. Please verify the details!'
          );
          setIsLoading(false);
      }
    }
  }

  async function redirectForExternalPaymentProcessing() {
    const generateBuyLinkRequest = {
      language: props?.language,
    };
    const response = await postAPIAsyncCall(
      `${process.env.REACT_APP_CART_URL}/external/generatebuylink`,
      generateBuyLinkRequest
    );

    if (
      response &&
      Number(response.status) >= STATUS_OK &&
      Number(response.status) < STATUS_REDIRECT
    ) {
      window.location.assign(response.headers.location);
    }
  }

  return (
    <div className="placeorderprocess">
      {props.sitecoreContext.pageEditing && (
        <>
          <p>PlaceOrderProcess Component Edit Mode</p>
        </>
      )}

      <div className="placeOrderButton">
        {props.isOrderLoading ? (
          <Button color="secondary" variant="contained" className="checkoutButton">
            <LoadingIcon />
          </Button>
        ) : (
          <Button
            color="secondary"
            variant="contained"
            className={
              props?.taxExemptPending === true ? 'checkoutButtonDisable' : 'checkoutButton'
            }
            title={props?.taxExemptPending === true ? 'Pending tax exempt to place the order' : ''}
            onClick={() => processOrder(props.orderId)}
            disabled={props.taxExemptPending}
          >
            <span
              className={
                props?.taxExemptPending === true
                  ? 'checkoutButtonTextDisable'
                  : 'checkoutButtonText'
              }
            >
              {props?.orderSummaryBtnText}
            </span>
          </Button>
        )}
      </div>

      <div>{placeOrderProcessStatus(isLoading)}</div>

      {navigateOnOrderSubmit.length > 1 ? <Redirect to={navigateOnOrderSubmit} /> : <></>}

      <div>{openAddressValidationPopup(confirmPopup)}</div>
      <ToastNotification
        fields={{
          ToastMessage: toastMessage,
        }}
        showToast={showToast}
        removeToast={toastRemove}
        toastStatus="error"
      ></ToastNotification>
    </div>
  );
};

export default withSitecoreContext()(PlaceOrderProcess);
