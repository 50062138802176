import React from 'react';
import { IProductMetaData } from '../model/ProductMetaData';
import { ISubscriptionProductOption } from '../model/ISubscriptionLanguageOption';

export interface IProductDetail {
  productMetaData?: IProductMetaData | undefined;
  productId?: string;
  productType?: string;
  edition?: string;
  subscription?: ISubscriptionProductOption;
}

export type ProductContextType = {
  productDetail?: IProductDetail;
};

export const ProductDataContext = React.createContext<ProductContextType | undefined>({});
