import React, { useContext, useEffect, useState } from 'react';
import {
  ComponentParams,
  ComponentRendering,
  ComponentFactory,
  SitecoreContext,
  Field,
  LinkFieldValue,
  ImageField,
} from '@sitecore-jss/sitecore-jss-react';

import './index.scss';
import { Card, CardContent, Grid, styled, Typography } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import { Link } from 'react-router-dom';
import PreviewStandardImage, { PreviewStandardImageDetails } from '../PreviewStandardImage';
import { createStyles, makeStyles, withStyles, Theme } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';
import InputBase from '@material-ui/core/InputBase';
import CustomTooltip from '../CustomTooltip';
import { getAPICall, postAPICall } from '../../dataFetcher/axiosMethodCalls';
import { APIWebstoreContext } from '../../context/globalContext';
import Quantity, { QuantityFieldsProps } from '../Quantity';
import { addEllipsis, getCurrencyInUSD } from '../../utils/helper';
import RichToastNotification, { RichToastMesssageProps } from '../RichToastNotification';
import RecipientEmail from '../RecipientEmail';
import LoadingSkeleton from '../LoadingSkeleton';
import nextId from 'react-id-generator';
import { WebstoreDataService } from '../../utils/WebstoreDataService';
import { getCountryConfigFields } from '../../utils/countryConfigurationHelper';

export type FormatTextFieldProps = {
  FormatType: Field<string>;
};

export type FormatOptionsFieldsProps = {
  fields: FormatTextFieldProps;
  displayName: string;
  name: string;
};

export type ProductCartDetailsProps = {
  fields: {
    Link: Field<LinkFieldValue>;
    FormatTooltipText: Field<string>;
    OptInUpgradeAgreementText: Field<string>;
    OptInUpgradeTooltipText: Field<string>;
    FormatOptions: Array<FormatOptionsFieldsProps>;
    ThumbnailImage: ImageField;
    confirmationMessage: Field<string>;
    okButtonText: Field<string>;
    cancelButtonText: Field<string>;
    ToastMessageForRemoveItem: Field<string>;
    RecipientEmailTooltip: Field<string>;
    EmailPlaceholderText: Field<string>;
    RecipientEmailPlaceholderText: Field<string>;
    PreviewBaseUrl: Field<string>;
    ThumbnailBaseUrl: Field<string>;
    DatasheetImage: ImageField;
    Countries: Array<ProductFormatOptionsFieldProps>;
  };

  previewStdFields: PreviewStandardImageDetails;
  quantityFields: QuantityFieldsProps;
  sitecoreContext: SitecoreContext & { pageEditing: boolean };
};

export type ProductFormatOptionsFieldProps = {
  displayName: string;
  fields: IFormatOptionFields;
  name: string;
};

export interface IFormatOptionFields {
  AllowedFormats: Array<FormatOptionsFieldsProps>;
}

export interface CartProductFieldsProps {
  title: string;
  productId: string;
  publicationNbr: string;
  edition: number;
  publishedDate: string;
  previewUrl: string;
  thumbnailUrl: string;
  status: string;
  isRedlineExist: boolean;
  isRedline: boolean;
  isRecent: boolean;
  isDatasheet: boolean;
  productType: string;
  publicationType: string;
}

export interface CartPriceOptionFieldsProps {
  format: string;
  isActive: boolean;
  language: string;
  quantity: number;
  unitPrice: number;
  variantId: string;
}

export interface CartDetails {
  orderId: string;
  quantity: number;
  items: Array<CartItemsDetails>;
}

export type CartItemsDetails = {
  lineItemId: string;
  product: CartProductFieldsProps;
  priceOption: CartPriceOptionFieldsProps;
  sendToEmailAddresses: Array<string>;
};

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

interface Props {
  children: React.ReactElement;
  componentFactory: ComponentFactory;
  rendering: ComponentRendering;
  params: ComponentParams;
}

const BootstrapInput = withStyles((theme: Theme) =>
  createStyles({
    root: {
      'label + &': {
        marginTop: theme.spacing(3),
      },
    },
    input: {
      borderRadius: 4,
      position: 'relative',
      backgroundColor: theme.palette.background.paper,
      border: '1px solid #ced4da',
      fontSize: 16,
      padding: '5px 26px 10px 12px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      // Use the system font instead of the default Roboto font.
      fontFamily: ['urw-din', 'sans-serif'].join(','),
      '&:focus': {
        borderRadius: 4,
        borderColor: '#80bdff',
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
      },
    },
  })
)(InputBase);

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    margin: {
      margin: theme.spacing(1),
    },
    root: {
      minWidth: 200,
    },
  })
);

export const ProductCartDetails = ({ fields }: ProductCartDetailsProps): JSX.Element => {
  const classes = useStyles();
  const [orderID, setOrderID] = useState('');
  const [cartItemDetails, setCartItemDetails] = useState(Array<CartItemsDetails>());
  const { state, dispatch } = useContext(APIWebstoreContext);
  const [showToast, setShowToast] = useState(false);
  const [toastMessages, setToastMessages] = useState<Array<RichToastMesssageProps>>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isRedlineLoading, setIsRedlineLoading] = useState('');
  const [isEmailLoading, setIsEmailLoading] = useState(false);
  const [countryConfigurationInfo, setCountryConfigurationInfo] = useState<IFormatOptionFields>();

  const handleFormatChange = (
    event: React.ChangeEvent<{ value: string }>,
    item: CartItemsDetails
  ) => {
    item.priceOption.format = event.target.value;
    formatUpdatePostCall(event.target.value, item);
  };
  const removeItem = (cartItem: CartItemsDetails) => {
    setCartItemDetails(cartItemDetails.filter((x) => x.lineItemId !== cartItem.lineItemId));

    if (toastMessages) {
      toastMessages.splice(0, toastMessages.length);
    }

    let messages = fields?.ToastMessageForRemoveItem.value.split('##').filter(Boolean);
    messages.map((item) => {
      if (item === 'PRODUCT_TITLE') {
        toastMessages.push({
          type: 'link',
          linkUrl: getProductUrl(cartItem.product),
          message: cartItem.product.title,
        });
      } else {
        toastMessages.push({
          type: 'text',
          message: item,
        });
      }
    });

    setToastMessages([...toastMessages]);
    setShowToast(true);
  };

  const toastRemove = () => {
    setShowToast(false);
  };

  const quantityUpdated = () => {
    setIsEmailLoading(true);
    fetchData();
    const maxcartItemQuantity = cartItemDetails.reduce(
      (op, item) => (op = op > item.priceOption.quantity ? op : item.priceOption.quantity),
      0
    );

    if (dispatch) {
      dispatch({
        type: 'CART_UPDATE',
        payload: { maxCartItemQuantity: maxcartItemQuantity },
      });
    }
  };

  const emailRemoved = () => {
    fetchData();
  };

  function formatUpdatePostCall(format: string, item: CartItemsDetails) {
    const formatUpdateRequest = {
      OrderId: orderID,
      lineItemId: item.lineItemId,
      format,
    };

    postAPICall(
      `${process.env.REACT_APP_CART_URL}/CartOrder/updateFormat`,
      formatUpdateRequest
    ).then(function (response: any) {
      if (response && response.status === 200) {
        fetchData();
        if (dispatch) {
          dispatch({
            type: 'CART_UPDATE',
            payload: {
              toggleOrderSummaryLoad: !state?.toggleOrderSummaryLoad,
            },
          });
        }
      }
    });
  }

  function getCartHeaderText(cartQuantity: number | undefined) {
    if (cartQuantity !== undefined && Number(cartQuantity) > 0) {
      return (
        <h1 className="cartPageTitle">
          Your Cart
          <span>
            {' '}
            ({cartQuantity} {cartQuantity > 1 ? 'Items' : 'Item'})
          </span>
        </h1>
      );
    } else {
      return (
        <div className="empty-cart">
          <h1 className="cartPageTitle red-title">Your Cart is empty</h1>
          <h3>
            Looks like you have not added anything to your cart. Go ahead and explore top standards.
          </h3>
        </div>
      );
    }
  }

  function upgradeRedline(item: CartItemsDetails, e: any) {
    postAPICall(
      `${process.env.REACT_APP_CART_URL}/CartOrder/${orderID}/lineitems/${item.lineItemId}/redlineupgrade`,
      {}
    )
      .then(function (response: any) {
        if (response && response.status === 200) {
          fetchData();
          if (dispatch) {
            dispatch({
              type: 'CART_UPDATE',
              payload: { toggleOrderSummaryLoad: !state?.toggleOrderSummaryLoad },
            });
          }
        }
      })
      .finally(() => {
        setIsRedlineLoading('');
      });
  }

  function removeRedline(item: CartItemsDetails, e: any) {
    postAPICall(
      `${process.env.REACT_APP_CART_URL}/CartOrder/${orderID}/lineitems/${item.lineItemId}/removeredline`,
      {}
    )
      .then(function (response: any) {
        if (response && response.status === 200) {
          fetchData();
          if (dispatch) {
            dispatch({
              type: 'CART_UPDATE',
              payload: { toggleOrderSummaryLoad: !state?.toggleOrderSummaryLoad },
            });
          }
        }
      })
      .finally(() => {
        setIsRedlineLoading('');
      });
  }

  function toggleRedline(item: CartItemsDetails, e: any) {
    setIsRedlineLoading(item.lineItemId);
    if (e.target.checked) {
      upgradeRedline(item, e);
    } else {
      removeRedline(item, e);
    }
  }

  function getOptInUpgradeRedline(item: CartItemsDetails, isLoggedIn: boolean | undefined) {
    if ((item.product?.isRedlineExist || item.product?.isRedline) && isLoggedIn) {
      let isRedline = item.product?.isRedline;
      return (
        <div className="optInDiv" key={nextId()}>
          <div className="optInNotifications" key={nextId()}>
            {isRedlineLoading === item.lineItemId ? (
              <LoadingSkeleton theme="light" minWidth="39px" loaderHeight="22px" />
            ) : (
              <input
                type="checkbox"
                className="checkbox"
                defaultChecked={isRedline}
                onChange={(e) => toggleRedline(item, e)}
                id={item.lineItemId}
                key={nextId()}
              ></input>
            )}
            <label className="optinnotificationtext" key={nextId()}>
              <p className="optInCartFullText" key={nextId()}>
                <span key={nextId()}>
                  <strong>Upgrade</strong>
                  <span className="upgradeTextTitleEdition" key={nextId()}>
                    to {item.product?.title}, {item.product?.edition}
                    <span className="upgradeTextTitleEdition" key={nextId()}>
                      Edition with
                    </span>
                  </span>
                  <span className="OptinLabelRedline" key={nextId()}>
                    Redline
                  </span>{' '}
                  (adds 15% to price of standard)
                </span>
              </p>
            </label>
            <CustomTooltip
              fields={{
                'Tooltip Text': fields.OptInUpgradeTooltipText,
              }}
            />
            <br></br>
          </div>
        </div>
      );
    } else {
      return <></>;
    }
  }

  function getProductCartSkeleton() {
    return (
      <Grid className="productCartGrid" item xs={9} sm={6} md={3}>
        <Card className={classes.root} variant="outlined">
          <CardContent>
            <Typography className="title" color="textSecondary" gutterBottom>
              <LoadingSkeleton />
            </Typography>

            <div className="cartDetailGrid">
              <div className="thumbnailsection">
                <LoadingSkeleton theme="light" loaderHeight="120px" />
              </div>
              <div className="productdetailssection">
                <div className="productInfoSection">
                  <div className="editionSection">
                    <p className="productInfoFieldTitle">Edition:</p>
                    <p className="productDetailInnerTitleText">
                      <LoadingSkeleton theme="light" />
                    </p>
                  </div>
                  <div className="publishedSection">
                    <p className="productInfoFieldTitle">Published:</p>
                    <p className="productDetailInnerTitleText">
                      <LoadingSkeleton theme="light" />
                    </p>
                  </div>
                </div>
                <div className="productInfoSection">
                  <div className="languageSection">
                    <p className="productInfoFieldTitle">Language:</p>
                    <p className="productDetailInnerTitleText">
                      <LoadingSkeleton theme="light" />
                    </p>
                  </div>
                  <div className="statusSection">
                    <p className="productInfoFieldTitle">Status:</p>
                    <p className="productDetailInnerTitleText">
                      <LoadingSkeleton theme="light" />
                    </p>
                  </div>
                </div>
                <div className="productInfoSection3">
                  <div className="formatSection">
                    <div className="formatTitleArea">
                      <p className="productInfoFieldTitle">Format:</p>
                      <CustomTooltip
                        fields={{
                          'Tooltip Text': fields.FormatTooltipText,
                        }}
                      />
                    </div>
                    <div>
                      {' '}
                      <LoadingSkeleton theme="light" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="pricedetailssection">
                <p className="priceTitle">Unit Price</p>
                <p className="unitPrice">
                  <LoadingSkeleton theme="light" />
                </p>
              </div>
            </div>
          </CardContent>
        </Card>
      </Grid>
    );
  }

  function getProductUrl(productDetails: CartProductFieldsProps) {
    let productType = productDetails.isDatasheet ? 'datasheets' : 'standards';
    let previousEdition = productDetails.isRecent ? '' : `?edition=${productDetails.edition}`;
    return `${productType}/${productDetails.productId}${previousEdition}`;
  }

  const fetchData = () => {
    getAPICall(`${process.env.REACT_APP_CART_URL}/cartOrder`)
      .then(function (response: any) {
        if (response && response.data) {
          if (dispatch) {
            dispatch({
              type: 'CART_UPDATE',
              payload: { cartCount: response.data.quantity },
            });
          }
          setCartItemDetails(response.data.items);
          WebstoreDataService.setCartInfo(response.data.items, false);
          setOrderID(response.data.orderId);
        }
      })
      .finally(() => {
        setIsLoading(false);
        setIsEmailLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    getShippingCountry();
  }, []);

  function getShippingCountry() {
    let shippingCountry: string = '';
    getAPICall(`${process.env.REACT_APP_CART_URL}/checkout/getshippingcountry`)
      .then(function (response: any) {
        if (response && response.data) {
          shippingCountry = response.data;
        }
      })
      .finally(() => {
        setCountryConfigurationInfo(getCountryConfigurationData(shippingCountry));
      });
  }

  function getCountryConfigurationData(shippingCountry: string) {
    let countryConfigurationData: Array<ProductFormatOptionsFieldProps> = [];

    countryConfigurationData = getCountryConfigFields(shippingCountry, fields.Countries);
    const countryConfigurationFields: IFormatOptionFields | undefined = {
      AllowedFormats: countryConfigurationData[0].fields.AllowedFormats,
    };

    return countryConfigurationFields;
  }

  function getFormatOptions(item: CartItemsDetails) {
    if (item.product.isDatasheet) {
      return (
        <option key={nextId()} value={item.priceOption.format}>
          {item.priceOption.format}
        </option>
      );
    } else {
      return fields.FormatOptions?.map((item: FormatOptionsFieldsProps, id: number) => {
        const allowedFormats = countryConfigurationInfo?.AllowedFormats.filter(
          (x: any) => x.name === item.name
        );

        const isDisabled = allowedFormats && allowedFormats.length > 0 ? false : true;
        return (
          <option key={nextId()} value={item.fields.FormatType.value} disabled={isDisabled}>
            {item.fields.FormatType.value}
          </option>
        );
      });
    }
  }

  return (
    <div>
      <RichToastNotification
        key="richToast"
        toastMessages={toastMessages}
        showToast={showToast}
        removeToast={toastRemove}
      ></RichToastNotification>
      <div className="cartDetailsSection">
        {getCartHeaderText(state?.cartCount)}
        {state?.cartCount && state?.cartCount > 0 ? (
          isLoading ? (
            getProductCartSkeleton()
          ) : (
            <Grid container direction="column" justifyContent="space-around" alignItems="stretch">
              {cartItemDetails?.map((item: CartItemsDetails, id: number) => {
                return (
                  <Grid className="productCartGrid" item xs={9} sm={6} md={3} key={nextId()}>
                    <Card className={classes.root} variant="outlined" key={nextId()}>
                      <CardContent key={nextId()}>
                        <Typography
                          className="title"
                          color="textSecondary"
                          gutterBottom
                          key={nextId()}
                        >
                          <Link
                            to={getProductUrl(item.product)}
                            id={`${item.product.productId}${item.product.edition}`}
                            key={nextId()}
                          >
                            {item.product?.title}
                            {addEllipsis(item.product?.title)}
                          </Link>
                        </Typography>
                        <div className="cartDetailGrid" key={nextId()}>
                          <div className="thumbnailsection" key={nextId()}>
                            <PreviewStandardImage
                              previewStdFields={{
                                previewUrl: item.product?.previewUrl,
                                thumbnailUrl: item.product?.thumbnailUrl,
                                isDatasheet: item.product?.isDatasheet,
                              }}
                              fields={{
                                ThumbnailImage: item.product?.isDatasheet
                                  ? fields.DatasheetImage
                                  : fields.ThumbnailImage,
                              }}
                              PreviewBaseUrl={fields?.PreviewBaseUrl}
                              ThumbnailBaseUrl={fields?.ThumbnailBaseUrl}
                            ></PreviewStandardImage>
                          </div>
                          <div className="productdetailssection" key={nextId()}>
                            <div className="productInfoSection" key={nextId()}>
                              <div className="editionSection" key={nextId()}>
                                <p className="productInfoFieldTitle" key={nextId()}>
                                  Edition:
                                </p>
                                <p className="productDetailInnerTitleText" key={nextId()}>
                                  {item.product?.edition}
                                </p>
                              </div>
                              <div className="publishedSection" key={nextId()}>
                                <p className="productInfoFieldTitle" key={nextId()}>
                                  Published:
                                </p>
                                <p className="productDetailInnerTitleText" key={nextId()}>
                                  {item.product.publishedDate.split(' ')[0]}
                                </p>
                              </div>
                            </div>
                            <div className="productInfoSection" key={nextId()}>
                              <div className="languageSection" key={nextId()}>
                                <p className="productInfoFieldTitle" key={nextId()}>
                                  Language:
                                </p>
                                <p className="productDetailInnerTitleText" key={nextId()}>
                                  {item.priceOption?.language}
                                </p>
                              </div>
                              <div className="statusSection key={nextId()}">
                                <p className="productInfoFieldTitle" key={nextId()}>
                                  Status:
                                </p>
                                <p className="productDetailInnerTitleText" key={nextId()}>
                                  {item.product?.status}
                                </p>
                              </div>
                            </div>
                            <div className="productInfoSection3" key={nextId()}>
                              <div className="formatSection" key={nextId()}>
                                <div className="formatTitleArea" key={nextId()}>
                                  <p className="productInfoFieldTitle" key={nextId()}>
                                    Format:
                                  </p>
                                  <CustomTooltip
                                    fields={{
                                      'Tooltip Text': fields.FormatTooltipText,
                                    }}
                                  />
                                </div>
                                <div className="formatDropdown" key={nextId()}>
                                  <FormControl className={classes.margin} key={nextId()}>
                                    <NativeSelect
                                      id="demo-customized-select-native"
                                      value={item.priceOption.format}
                                      onChange={(e) => handleFormatChange(e, item)}
                                      input={<BootstrapInput />}
                                      key={nextId()}
                                    >
                                      {getFormatOptions(item)}
                                    </NativeSelect>
                                  </FormControl>
                                </div>
                              </div>
                            </div>
                            {item.priceOption.format !== 'Print Edition' && (
                              <RecipientEmail
                                key={nextId()}
                                fields={{
                                  RecipientEmailTooltip: fields.RecipientEmailTooltip,
                                  EmailPlaceholderText: fields.EmailPlaceholderText,
                                  RecipientEmailPlaceholderText:
                                    fields.RecipientEmailPlaceholderText,
                                  MaxQuantity: item.priceOption.quantity,
                                }}
                                cartItemFields={{ orderId: orderID, cartItem: item }}
                                IsEmailLoading={isEmailLoading}
                                emailRemoved={emailRemoved}
                              ></RecipientEmail>
                            )}
                          </div>
                          <div className="pricedetailssection" key={nextId()}>
                            <p className="priceTitle" key={nextId()}>
                              Unit Price
                            </p>
                            <p className="unitPrice" key={nextId()}>
                              {getCurrencyInUSD(item.priceOption?.unitPrice)}
                            </p>
                            <Quantity
                              key={nextId()}
                              fields={{
                                confirmationMessage: fields.confirmationMessage,
                                okButtonText: fields.okButtonText,
                                cancelButtonText: fields.cancelButtonText,
                              }}
                              quantityFields={{
                                orderId: orderID,
                                cartItem: item,
                              }}
                              properties={{
                                divId: '',
                                removeLinkId: '',
                              }}
                              removeItem={removeItem}
                              quantityUpdated={quantityUpdated}
                            ></Quantity>
                          </div>
                        </div>
                        {getOptInUpgradeRedline(item, state?.loggedIn)}
                      </CardContent>
                    </Card>
                  </Grid>
                );
              })}
            </Grid>
          )
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default ProductCartDetails;
