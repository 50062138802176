import { ResultList as HeadlessResultList } from '@coveo/headless';
import React from 'react';
import { FunctionComponent, useEffect, useState } from 'react';
import { addEllipsis, getCurrencyInUSD } from '../../utils/helper';
import { Link } from 'react-router-dom';

import CheckCircle from '@material-ui/icons/CheckCircle';
import HistoryCircle from '@material-ui/icons/HistoryRounded';
import CartSlider from '../CartSlider';
import Button from '@material-ui/core/Button';
import nextId from 'react-id-generator';
import { InteractiveResult } from './interactive-result';
import RelatedPrograms, { RelatedProgramsFieldsProps } from '../RelatedPrograms';
import { Field } from '@sitecore-jss/sitecore-jss-react';
import { IPriceOptionFields } from '../PriceOptions';

interface ResultListProps {
  controller: HeadlessResultList;
  Programs: Array<RelatedProgramsFieldsProps>;
  ProgramCount: Field<number>;
  countryConfigFields?: IPriceOptionFields;
  formatOptions?: any;
}

export const ResultList: FunctionComponent<ResultListProps> = (props) => {
  const { controller } = props;
  const [state, setState] = useState(controller.state);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [productId, setProductId] = useState<string>('');
  const [productType, setProductType] = useState<string>('');
  const [productTitle, setProductTitle] = useState<string>('');
  const [edition, setEdition] = useState<string>('');
  const [expandMode, setExpandMode] = useState(false);

  useEffect(() => controller.subscribe(() => setState(controller.state)), [controller]);

  function toggleAccordion() {
    setExpandMode((prev) => !prev);
  }

  if (!state.results.length) {
    return <div>No results</div>;
  }

  const handleClick = (
    event: React.MouseEvent<HTMLElement>,
    productId: string,
    productType: string,
    title: string,
    edition: string,
    expand?: boolean
  ) => {
    setProductId(productId);
    setProductType(productType);
    setProductTitle(title);
    setEdition(edition);
    setExpandMode(expand || false);
    setOpenDrawer(true);
  };

  const handleClose = () => {
    setOpenDrawer(false);
  };

  function getRelatedPrograms(programs: Array<string> | any): Array<string> {
    if (programs && programs !== undefined) {
      return programs;
    }
    return [];
  }

  return (
    <div className="result-list">
      {state.results.map((result) => {
        const search_path = result.raw.isdatasheet === 'True' ? 'datasheets' : 'standards';
        const to_navigate =
          result.raw.isdatasheet === 'True'
            ? result.raw.ismostrecent === 'True'
              ? `/${search_path}/${result.raw.productcode}`
              : `/${search_path}/${result.raw.productcode}?edition=${result.raw.edition}`
            : `/${search_path}/${result.raw.productcode}`;
        return result && result.raw ? (
          <div key={result.uniqueId} className="outer-box">
            <div className="box-frame">
              <div className="result-container">
                <div className="result-details-container">
                  <div className="result-header">
                    <InteractiveResult result={result} to_href={to_navigate}>
                      {result.title}
                      {addEllipsis(result.title)}
                    </InteractiveResult>
                  </div>
                  <div className="result-subheader">
                    {result.raw.ismostrecent === 'True' ? (
                      <div className="result-recent" key={nextId()}>
                        <CheckCircle className="result-recent-icon" />
                        {result.raw.mostrecenttext}
                      </div>
                    ) : (
                      <div className="result-previous" key={nextId()}>
                        <HistoryCircle className="result-recent-icon" />
                        {result.raw.historicaltext}
                      </div>
                    )}
                    <div className="result-edition" key={nextId()}>
                      <b>Edition:</b> {result.raw.edition}
                    </div>
                    <div className="result-status" key={nextId()}>
                      <b>Status: </b>
                      {result.raw.status}
                    </div>
                  </div>

                  <div className="result-main" key={nextId()} title={'' + result.raw.description}>
                    <> {result.raw.description}</>
                  </div>

                  <div className="result-footer" key={nextId()}>
                    {result.raw.isaddendaerrataavailable === 'True' ? (
                      <Link
                        className="navbar-item"
                        to={`/${search_path}/${result.raw.productcode}`}
                        key={nextId()}
                      >
                        <> {result.raw.addendaerratatext}</>
                      </Link>
                    ) : (
                      ''
                    )}
                    {result.raw.ispreviousedition === 'True' ? (
                      <Link
                        className="navbar-item"
                        to={`/${search_path}/${result.raw.productcode}`}
                        key={nextId()}
                      >
                        <> {result.raw?.historicaleditionstext}</>
                      </Link>
                    ) : (
                      ''
                    )}
                    {String(result.raw.otherlanguagesavailable) === 'True' ? (
                      <Link
                        className="navbar-item"
                        to="#"
                        key={nextId()}
                        onClick={(event) =>
                          handleClick(
                            event,
                            String(result.raw.productcode),
                            search_path,
                            String(result.title),
                            String(result.raw.edition),
                            true
                          )
                        }
                      >
                        <> {result.raw.otherlanguagestext}</>
                      </Link>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
                <div className="result-cart-container">
                  <div className="result-cart">
                    <div className="product-price">
                      <>{getCurrencyInUSD(result.raw.price)}</>
                    </div>
                    <div className="cart-button-div">
                      <Button
                        className="ws-white-button"
                        variant="contained"
                        key={nextId()}
                        onClick={(event) =>
                          handleClick(
                            event,
                            String(result.raw.productcode),
                            search_path,
                            String(result.title),
                            String(result.raw.edition)
                          )
                        }
                      >
                        ADD TO CART
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {result.raw.programs && (
              <RelatedPrograms
                fields={{
                  Programs: props.Programs,
                  ProgramCount: props.ProgramCount,
                }}
                RelatedPrograms={getRelatedPrograms(result.raw.programs)}
                IsSearchResultsPage={true}
              ></RelatedPrograms>
            )}
          </div>
        ) : (
          <></>
        );
      })}
      <CartSlider
        productId={productId}
        productType={productType}
        productTitle={productTitle}
        edition={edition}
        openSlider={openDrawer}
        expandMode={expandMode}
        closeSlider={handleClose}
        toggleAccordion={toggleAccordion}
        loadPriceOptions={true}
        formatOptions={props.formatOptions}
        countryConfigFields={props.countryConfigFields}
      ></CartSlider>
    </div>
  );
};
