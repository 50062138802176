import React, { useContext, useEffect, useState } from 'react';

import './index.scss';
import PriceOptions, {
  getAvlLanguage,
  IPriceOptionFields,
  LanguageOptionProps,
} from '../PriceOptions';
import { Drawer } from '@material-ui/core';
import { getAPICall, postAPICall } from '../../dataFetcher/axiosMethodCalls';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { APIWebstoreContext } from '../../context/globalContext';
import { addEllipsis, getCurrencyInUSD } from '../../utils/helper';
import { Redirect } from 'react-router-dom';
import LoadingIcon from '../LoadingIcon';
import LoadingSection from '../LoadingSection';
import nextId from 'react-id-generator';
import { CartAnalyticsInfo, addCartEvent, checkoutEvent } from '../../utils/analyticsHelper';

export type CartSliderProps = {
  productId: string;
  productType: string;
  productTitle: string;
  edition: string;
  languageOptions?: Array<LanguageOptionProps>;
  openSlider: boolean;
  closeSlider?: any;
  expandMode?: boolean;
  toggleAccordion: any;
  loadPriceOptions?: boolean;
  isProductActive?: boolean;
  countryConfigFields?: IPriceOptionFields;
  formatOptions?: any;
};

export const CartSlider = (props: CartSliderProps): JSX.Element => {
  const [subTotal, setSubTotal] = useState(0);
  const { state, dispatch } = useContext(APIWebstoreContext);
  const [isLoading, setIsLoading] = useState(props.loadPriceOptions);
  const [open, setOpen] = React.useState(true);
  const [languageOptions, setLanguageOptions] = useState(Array<LanguageOptionProps>());
  const [isProductActive, setIsProductActive] = useState(props.isProductActive);
  const [isAddToCart, setIsAddToCart] = useState(false);
  const [isAddToCartAndCheckout, setIsAddToCartAndCheckout] = useState(false);
  const [redirectToCheckout, setRedirectToCheckout] = useState(false);
  const [isItemsUpdated, setIsItemsUpdated] = useState(false);

  const handleLoadingClose = () => {
    setOpen(false);
    setIsLoading(false);
  };

  useEffect(() => {
    if (!props.openSlider) {
      return;
    }

    setIsLoading(props.loadPriceOptions);
    if (props.loadPriceOptions && props.productId && props.productId.length > 0) {
      loadPricing(props.productId, props.edition);
    } else if (props.languageOptions) {
      setLanguageOptions(props.languageOptions);
      calculateTotalWithOptions(props.languageOptions);
      checkQuantityCountWithOptions(props.languageOptions);
    }
    setIsProductActive(props.isProductActive);
  }, [
    props.productId,
    props.edition,
    props.languageOptions,
    props.isProductActive,
    props.productType,
    props.openSlider,
  ]);

  function AddToCart(redirect: boolean) {
    const addToCartRequest = {
      productId: props.productId,
      edition: props.edition,
      languageOptions: languageOptions,
    };
    postAPICall(`${process.env.REACT_APP_CART_URL}/cartOrder`, addToCartRequest).then(function (
      response: any
    ) {
      if (response) {
        props.closeSlider();
        if (dispatch) {
          dispatch({
            type: 'CART_UPDATE',
            payload: {
              toggleCartQuantityLoad: !state?.toggleCartQuantityLoad,
            },
          });
        }
        setIsAddToCart(false);
        if (redirect) {
          setIsAddToCartAndCheckout(false);
          setRedirectToCheckout(true);
        }
      }
    });
  }

  const handleClose = () => {
    // API call to save cart quanity
    props.closeSlider();
    setSubTotal(0);
  };

  const handleAddToCart = (e: any) => {
    setIsAddToCart(true);
    // API call to save cart quanity
    AddToCart(false);
    formJSONAndPushToGTM(false);
    e.preventDefault();
  };

  const handleAddToCartAndCheckout = (e: any) => {
    setIsAddToCartAndCheckout(true);
    // API call to save cart quanity
    AddToCart(true);
    formJSONAndPushToGTM(true);
    e.preventDefault();
  };

  const calculateTotal = () => {
    calculateTotalWithOptions(languageOptions);
  };

  const calculateTotalWithOptions = (langOptions: Array<LanguageOptionProps>) => {
    if (langOptions) {
      setSubTotal(
        langOptions.reduce((accumulator, options) => {
          return options.priceOptions.reduce(
            (accumulator, price) => (accumulator += price.quantity * price.unitPrice),
            accumulator
          );
        }, 0)
      );
    }
  };

  const loadPricing = (productId: String, edition: String) => {
    if (props.loadPriceOptions && productId && productId.length > 0) {
      let previousEdition = edition && edition.length > 0 ? `/${edition}` : '';
      let apiUrl = `${process.env.REACT_APP_CATALOG_URL}/catalog/price/${productId}/`;
      if (props.productType === 'datasheets') {
        apiUrl += 'datasheet';
      } else {
        apiUrl += 'standard';
      }
      getAPICall(`${apiUrl}${previousEdition}`)
        .then(function (response: any) {
          if (response && response.data) {
            setLanguageOptions([...response.data.languageOptions]);
            setIsProductActive(response.data.isProductActive);
            calculateTotalWithOptions(response.data.languageOptions);
            checkQuantityCountWithOptions(response.data.languageOptions);
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  function checkQuantityCount() {
    checkQuantityCountWithOptions(languageOptions);
  }

  function checkQuantityCountWithOptions(langOptions: Array<LanguageOptionProps>) {
    let isItemsAdded = false;

    if (
      langOptions &&
      langOptions.some((options) => options.priceOptions.some((price) => price.quantity > 0))
    ) {
      isItemsAdded = true;
    } else {
      isItemsAdded = false;
    }
    setIsItemsUpdated(isItemsAdded);
  }

  const formJSONAndPushToGTM = (triggerCheckoutEvent: boolean) => {
    let value: any;
    value = subTotal.toFixed(2);
    const itemAnalyticsInfo: CartAnalyticsInfo = {
      currency: 'USD',
      value: value,
      items: [],
    };
    if (languageOptions) {
      languageOptions.filter(function (langOptions) {
        langOptions?.priceOptions?.filter(function (priceOptions) {
          let price: any = priceOptions?.unitPrice;
          price = price.toFixed(2);
          if (priceOptions.quantity > 0) {
            itemAnalyticsInfo.items.push({
              item_id: `${props?.productId}`,
              item_name: `${props?.productTitle}`,
              item_brand: `${props?.productType}`,
              item_variant: priceOptions?.variantId,
              price: price,
              quantity: priceOptions?.quantity,
            });
          }
        });
      });
    }

    addCartEvent(itemAnalyticsInfo);

    if (triggerCheckoutEvent) {
      checkoutEvent(itemAnalyticsInfo);
    }
  };

  function disableCheckoutButton() {
    if (state?.cartCount === 0 && !isItemsUpdated) {
      return true;
    }
    return false;
  }

  function showGoToCheckoutButton() {
    if (disableCheckoutButton() || (state?.cartCount !== 0 && !isItemsUpdated)) {
      return true;
    } else {
      return false;
    }
  }

  return (
    <div className="cartSlider">
      <React.Fragment>
        <Drawer anchor="right" open={props.openSlider} onClose={handleClose} className="cartDrawer">
          <IconButton onClick={handleClose} className="cartSliderClose">
            <CloseIcon />
          </IconButton>
          <h1>Add to cart</h1>
          <hr className="cartSliderHR" />
          {isLoading ? (
            <div className="cartLoadingSection">
              <LoadingSection
                open={open}
                onClick={handleLoadingClose}
                size="fullSize"
                message="Please wait..."
              />
            </div>
          ) : (
            <>
              <div className="salesprice">
                <div className="salesPriceSection">
                  <h1 className="salesPriceProductTitle" key={nextId()}>
                    {props.productTitle}
                    {addEllipsis(props.productTitle)}
                  </h1>
                  {languageOptions && languageOptions.length > 0 && (
                    <PriceOptions
                      calculateTotal={calculateTotal}
                      checkQuantityCount={checkQuantityCount}
                      key={nextId()}
                      priceOptions={
                        languageOptions.filter((x) => x.isDefault === true)[0]?.priceOptions
                      }
                      isProductActive={isProductActive}
                      formatOptions={props.formatOptions}
                      countryConfigFields={props.countryConfigFields}
                    />
                  )}
                  {languageOptions &&
                    languageOptions.length > 0 &&
                    getAvlLanguage(
                      languageOptions.filter((x) => x.isDefault === false),
                      calculateTotal,
                      checkQuantityCount,
                      props.expandMode,
                      props.toggleAccordion,
                      isProductActive,
                      props.countryConfigFields,
                      props.formatOptions
                    )}
                </div>
              </div>
              <div className="cartNavigationLinkSection">
                <p>
                  <span>Cart Subtotal</span>
                  <span className="cartSubTotal">
                    {subTotal ? getCurrencyInUSD(subTotal) : getCurrencyInUSD(0)}
                  </span>
                </p>
                <div className="cartNavButtons">
                  <a
                    className={`addtoCartButton ${
                      isItemsUpdated ? '' : 'add-to-cart-btn-disable'
                    } `}
                    href="#"
                    {...(!isAddToCart ? { onClick: handleAddToCart } : {})}
                  >
                    {isAddToCart ? (
                      <LoadingIcon />
                    ) : (
                      state?.sitecoreDictionaryData?.ADD_TO_CART || 'ADD TO CART'
                    )}
                  </a>
                  <a
                    className={
                      !disableCheckoutButton()
                        ? 'addtoCartButton addtoCartAndCheckoutButton'
                        : 'addtoCartButton addtoCartAndCheckoutButton add-to-cart-btn-disable'
                    }
                    href="#"
                    {...(!isAddToCartAndCheckout ? { onClick: handleAddToCartAndCheckout } : {})}
                  >
                    {isAddToCartAndCheckout ? (
                      <LoadingIcon />
                    ) : showGoToCheckoutButton() ? (
                      state?.sitecoreDictionaryData?.GO_TO_CHECKOUT || 'GO TO CHECKOUT'
                    ) : (
                      state?.sitecoreDictionaryData?.ADD_TO_CART_AND_CHECKOUT ||
                      'ADD TO CART AND CHECKOUT'
                    )}
                    {redirectToCheckout ? <Redirect to="/order/checkout" /> : <></>}
                  </a>
                </div>
              </div>
            </>
          )}
        </Drawer>
      </React.Fragment>
    </div>
  );
};

export default CartSlider;
